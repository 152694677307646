import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap/dist/js/bootstrap.js';
import Countdown from './counter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebookF, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

class App extends Component {

  render() {
    return (
      <div className="App">
        <div className='layer'></div>
        <nav>
          <div className="container">
            <div className="row">

              <div className='logo-cont col-6'>
                <img src='https://i.ibb.co/Tm0TdNK/Microsoft-Teams-image.png' />
              </div>

              <div className='icons-cont col-6'>
                <button type="button" className='icons-btn' data-toggle="modal" data-target="#exampleModal">
                  <i> <FontAwesomeIcon icon={faPhone} /> </i>
                </button>

                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-sm">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Contact us on:</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <p> +2 01221023358 </p>
                        <p> +2 01002791937 </p>
                        <p> +2 01096544224 </p>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="https://wa.me/+201221023358" target="_blank" className='icons-btn'>
                  <i> <FontAwesomeIcon icon={faWhatsapp} /> </i>
                </a>
                <a href="mailto:info@itcloudhub.com" target="_blank" className='icons-btn'>
                  <i> <FontAwesomeIcon icon={faEnvelope} /> </i>
                </a>
                <a href="https://www.facebook.com/" target="_blank" className='icons-btn'>
                  <i> <FontAwesomeIcon icon={faFacebookF} /> </i>
                </a>
                <a href="https://www.instgram.com/" target="_blank" className='icons-btn'>
                  <i> <FontAwesomeIcon icon={faInstagram} /> </i>
                </a>
                <a href="https://www.youtube.com/" target="_blank" className='icons-btn'>
                  <i> <FontAwesomeIcon icon={faYoutube} /> </i>
                </a>
              </div>


            </div>
          </div>
        </nav>

        <section className='counter-sec'>
          <div className='container'>
            <div className='row justify-content-center'>

              <div className='col-lg-6 show-logo-responsive'>
                <img className='w-100' src='https://i.ibb.co/Nt1gYjc/cloud-header-png2.png' />
              </div>

              <div className='col-lg-6 data-cont'>
                <h3> <b>Your Digital Transformation Partner </b> </h3>
                <h1 className='diff-color'>Coming Soon </h1>
                <h5>
                  <q> Our amazing website is under construction, we are working very hard to give you the best experience with us </q>
                </h5>
                <Countdown timeTillDate="02 28 2023, 6:00 pm" timeFormat="MM DD YYYY, h:mm a" />
              </div>
              <div className='col-lg-6 hide-logo-responsive'>
                <img className='w-100' src='https://i.ibb.co/Nt1gYjc/cloud-header-png2.png' />
              </div>

            </div>
          </div>
        </section>

      </div>

    );

  }
}


export default App;
