import React from 'react';
import moment from 'moment';

class Countdown extends React.Component {
    state = {
        month: undefined,
        days: 0,
        hours: undefined,
        minutes: undefined,
        seconds: undefined
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            const { timeTillDate, timeFormat } = this.props;
            const then = moment(timeTillDate, timeFormat);
            const now = moment();
            const countdown = moment(then - now);
            const month = countdown.format('M');
            const days = countdown.format('D');
            const hours = countdown.format('H');
            const minutes = countdown.format('mm');
            const seconds = countdown.format('ss');

            this.setState({ month, days, hours, minutes, seconds });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { month, days, hours, minutes, seconds } = this.state;

        if (!seconds) {
            return null;
        }

        return (
            <div className='counter'>
                <p>Launching in :  </p>
                {month && (
                    <div className='counter-cont'>
                        <span className='counter-number' > {month} </span>
                        <span> M </span>
                    </div>
                )}
                {days && (
                    <div className='counter-cont'>
                        <span className='counter-number' > {days} </span>
                        <span> D </span>
                    </div>
                )}
                {hours && (
                    <div className='counter-cont'>
                        <span className='counter-number'> {hours} </span>
                        <span > H </span>
                    </div>
                )}
                {minutes && (
                    <div className='counter-cont'>
                        <span className='counter-number'> {minutes} </span>
                        <span> M </span>
                    </div>
                )}
                {seconds && (
                    <div className='counter-cont'>
                        <span className='counter-number'> {seconds} </span>
                        <span > S </span>
                    </div>
                )}
            </div>
        );
    }
}


export default Countdown;